import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Meta from '../components/Meta'
import Base from '../components/Base'
import { ListLink } from './styles'

const Tags = props => {
  const { tag, pathName } = props.pageContext
  const { edges, totalCount } = props.data.allMdx
  const postWord = `post${totalCount === 1 ? '' : 's'}`
  const tagHeader = `${totalCount} ${postWord} tagged with "${tag}"`

  return (
    <Base pathName={pathName}>
      <Meta location={props.location} pageTitle={`Posts tagged with ${tag}`} />
      <h2>{tagHeader}</h2>
      <ul>
        {edges.map(({ node }) => (
          <li key={node.fields.slug}>
            <ListLink to={node.fields.slug}>{node.frontmatter.title}</ListLink>
          </li>
        ))}
      </ul>
      <Link to="/tags">All tags</Link>
    </Base>
  )
}

Tags.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    pathName: PropTypes.string
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 1000
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
          id
        }
      }
    }
  }
`
